import Service from '../Service';

const resource = "countingbocespresentation/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "save", obj , {
            params: { requestID }
        });
    },


    packingboxes(obj, requestID) {
        return Service.post(resource + "packingboxes", obj, {
            params: { requestID }
        });
    },

    pagination(obj, requestID) {
        console.log("filtres", obj);
        return Service.post(resource + "pagination", obj, {
            params: { requestID: requestID }
        });
    },

    transferCountingBoxes(list , requestID) {
        console.log(list);   
        return Service.post( resource+"tranferCountingBoxes",list, {
            params: { requestID: requestID }
        });
    },

}