<template>
    <v-container>
	    <div>
            <s-crud
                    title="Listado de Conteo Etiquetas"
                    :config="config"
                    :filter="filter"
                    remove
                    ref="gridTagCountList"
                    @rowSelected="rowSelected($event)"                     
                    :single-row="false"
                >
                <template v-slot:filter>
                    <v-container>
                        <v-row>
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition
                                    label="Destino"
                                    :def="1181"
                                    v-model="filter.TypeDestiny"
                                    @change="getTypeArea(filter.TypeDestiny)"							
                                    >
                                </s-select-definition>
                            </v-col>
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition
                                    :dgr="dgr"
                                    label="Linea Empaque"
                                    :def="1315"
                                    v-model="filter.TypeLine"
                                    
                                    >
                                </s-select-definition>
                            </v-col>
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition
                                    label="Faja"
                                    :def="1472"
                                    v-model="filter.TypeBelt"
                                    >
                                </s-select-definition>
					        </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" lg="4" md="4">
                                <s-text 
                                    label="Documento" 
                                    number
                                    v-model="filter.CbpNumberDocument"
                                    ref="stext"
                                ></s-text>
					        </v-col>
                            <v-col cols="4" lg="4" md="4">
                                <s-select
                                label="Turno de Produccion"
                                :items="itemTurn"
                                item-value="TrnID"
                                item-text="TnrObservation"
                                v-model="processInitial.TrnID"
                                >  
                                </s-select>
                            </v-col>
                            <v-col cols="2" lg="2" md="2">
                                <v-btn
                                    block
                                    rounded
                                    color="primary"
                                    @click="Transfer()">
                                    Transferencia  
                                    <i class="fas fa-exchange-alt  fa-3x"></i>
                                </v-btn>     
                            </v-col>      
                        </v-row>
                    </v-container>
                </template>
                         
            </s-crud>
        </div>
            <v-dialog persistent v-model="openModal" v-if="openModal" width="700">
			<v-card>
				<v-col>
					<v-row>
						<s-toolbar
							save
							@save="save({})"
							:color="'#BAB6B5'"
							dark
							label=""
							close
							@close="close()"
						/>
					</v-row>

					<v-row>
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition
                                    label="Destino"
                                    :def="1181"
                                    v-model="item.TypeDestiny"
                                    @change="getTypeArea(item.TypeDestiny)"									
                                    >
                                </s-select-definition>
                            </v-col>
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition
                                    :dgr="dgr"
                                    label="Linea Empaque"
                                    :def="1315"
                                    v-model="item.TypeLine"                                   
                                    >
                                </s-select-definition>
                            </v-col>
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition
                                    label="Faja"
                                    :def="1472"
                                    v-model="item.TypeBelt"
                                    >
                                </s-select-definition>
					        </v-col>
                        </v-row>
				</v-col>
			</v-card>
		    </v-dialog>    
    </v-container>
</template>

<script>
    import _sPrfCountingBoxesPresentationService from "@/services/FreshProduction/PrfCountingBoxesPresentation"
    import _sTurnService from "@/services/FreshProduction/PrfTurnService"; // SERVICIO DEL TURNO


    export default {
		components: {
			//SSelectDefinition,
			//SSelectVariety,
			//SSupplier,
		},
        data() {
			return {
				//client:             null,
				filter: {
					
				},
				config: {
                    title: "Listado de Conteo de Etiquetas",
					model: {
						CbpID: "ID",
                        SecCreate: "datetime"					
						
					},
					service: _sPrfCountingBoxesPresentationService,
					headers: [
						{ text: "ID",                   value: "CbpID",                         width: 50,  },
                        //{ text: "Cliente",              value: "CardName",                    width: 150, },
						{ text: "DNI",                  value: "CbpNumberDocument",             width: 50,  },
                        { text: "PERSONA",              value: "NtpFullName",                   width: 150, },
						{ text: "Linea",	            value: "TypeLineName",                  width: 50,  },					
                        { text: "Destino",              value: "TypeDestinyName",               width: 100, },                   
                        { text: "Calibre",              value: "TypeCaliberName",               width: 100, },
                        { text: "Faja",                 value: "TypeBeltName",                  width: 100, },
                        { text: "Fecha de Creacion",    value: "SecCreate",                     width: 100, },
					],
				},				
				TypeDestiny:    1,
                TypeLine: 1,
                TypeBelt: 1,
				dgr:          70,
                openModal: false,
                item: {},
                itemsSelected: [],
                itemTurn: []    ,
                processInitial:{} ,
                TypeArea: 2        
			};
		},
        watch: {
			
        },
        methods: {
			rowSelected(item) {
				this.itemsSelected = item;
				console.log("item", this.itemsSelected);
			},
            save(items) {				
                           
               console.log("turnos1111", this.itemTurn);
               if(this.itemTurn.length === 0) {
                this.$fun.alert("Error, No hay turno activo para este destino", "warning")
				return;
               }

                this.itemsSelected.forEach(element => {
                    element.TypeDestiny = this.item.TypeDestiny,
                    element.TypeLine    = this.item.TypeLine,
                    element.TypeBelt    = this.item.TypeBelt,
                    element.TrnID       = this.itemTurn[0].TrnID,
                    element.TypeArea    = this.TypeArea   
                });

                console.log("items" , this.itemsSelected);
                _sPrfCountingBoxesPresentationService.transferCountingBoxes(this.itemsSelected,this.$fun.getUserID()).then((r) => {
                    if(r.status == 200) {
                        this.$fun.alert("Tranferidos Correctamente", "success");
                        //this.clear();
                        this.$refs.gridTagCountList.refresh();			
                        this.$emit('EndProcess', r.status);							
                    } 
                    else { 
                        this.$fun.alert("Ocurrio un error al Transferir", "error");
                        this.$emit('EndProcess', null);
                    }	
                                
                })
                    //item.save();
			},
            Transfer(){
                console.log("Items",this.itemsSelected);
                if(this.itemsSelected.length == 0){
					this.$fun.alert("Error, no se han seleccionado registros", "warning")
					return;
				}
                this.openModal          = true;
                this.item.TypeDestiny   = this.itemsSelected[0].TypeDestiny;
                this.item.TypeLine      = this.itemsSelected[0].TypeLine;
                this.item.TypeBelt      = this.itemsSelected[0].TypeBelt;
                
            },
            
            close() {
				this.openModal = false;	
			},

            TurnActive(){
            let TypeArea = 2
            console.log("TypeArea: ", this.TypeArea);
            _sTurnService.turnActivePrf({TypeArea : this.TypeArea, TypeShip: 1}, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        console.log("Turnos",r.data);
                        this.itemTurn = r.data;
                        if(this.itemTurn.length > 0)
                        this.processInitial.TrnID= this.itemTurn[0].TrnID;                               
                    }
                });      
            },
            getTypeArea(event){
               // console.log("destino",this.filter.TypeDestiny);
                console.log("destino22",event);
                switch(event){
                    case 1:
                        this.TypeArea = 2;
                        break;
                    case 2:
                        this.TypeArea = 3;
                        break;
                    case 9:
                        this.TypeArea = 10;
                        break;
                }                                
                console.log("TypeArea",this.TypeArea);
                this.itemTurn = [];                 
                this.TurnActive();
            }
        },
        created () {
            this.TurnActive();            
	    },	
	};
</script>